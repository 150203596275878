import(/* webpackMode: "eager" */ "/vercel/path0/app/preload-resources.js");
import(/* webpackMode: "eager" */ "/vercel/path0/helpers/client/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/helpers/store.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@storyblok/react/dist/bridge-loader.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@storyblok/react/dist/story.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.js\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\",\"display\":\"swap\",\"fallback\":[\"sans-serif\"]}],\"variableName\":\"inter\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.js\",\"import\":\"Benne\",\"arguments\":[{\"weight\":[\"400\"],\"subsets\":[\"latin\"],\"variable\":\"--font-benne\",\"display\":\"swap\",\"fallback\":[\"sans-serif\"]}],\"variableName\":\"benne\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/layout.js\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../config/font-faces/BeVietnam-Light.woff2\",\"weight\":\"300\"},{\"path\":\"../config/font-faces/BeVietnam-Regular.woff2\",\"weight\":\"400\"},{\"path\":\"../config/font-faces/BeVietnam-Medium.woff2\",\"weight\":\"500\"},{\"path\":\"../config/font-faces/BeVietnam-SemiBold.woff2\",\"weight\":\"600\"},{\"path\":\"../config/font-faces/BeVietnam-Bold.woff2\",\"weight\":\"700\"}],\"variable\":\"--font-be-vietnam\"}],\"variableName\":\"beVietnam\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/styles/index.css")