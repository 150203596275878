'use client';

import { useEffect } from 'react';

export const useDocumentTheme = ({ storyTheme }) => {
  useEffect(() => {
    if (typeof document !== 'undefined') {
      if (['grey-100'].includes(storyTheme)) {
        document.documentElement?.classList?.add?.('--theme-light');
        document.documentElement?.classList?.remove?.('--theme-dark');
      } else {
        document.documentElement?.classList?.add?.('--theme-dark');
        document.documentElement?.classList?.remove?.('--theme-light');
      }
    }
  }, [storyTheme]);
};
