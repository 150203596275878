'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import { Suspense, useEffect, useRef } from 'react';

import { pushDataLayerEvent } from '@/helpers/analytics/data-layer';

const PageView = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const first = useRef(true);
  useEffect(() => {
    if (first.current) {
      first.current = false;

      return;
    }

    if (pathname) {
      pushDataLayerEvent({
        event: 'page_view',
        page: pathname,
      });
    }
  }, [pathname, searchParams]);

  return null;
};

export const Analytics = () => {
  return (
    <Suspense>
      <PageView />
    </Suspense>
  );
};
