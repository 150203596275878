'use client';

import { useEffect } from 'react';

import { Analytics } from '@/helpers/analytics';
import { AnimateOnScroll } from '@/helpers/client/animate-on-scroll';
import { CookieConsent } from '@/helpers/client/cookie-consent';
import { useDocumentTheme } from '@/helpers/client/document-theme';
import { useStoryblok } from '@/helpers/client/storyblok';

/**
/**
 * Dynamically load on scroll animation
 */
export const Client = ({ draftModeEnabled, locale, storyTheme }) => {
  useDocumentTheme({ storyTheme });

  useEffect(() => {
    // If not an iframe or a Vercel Preview deployment, turn off Draft Mode
    if (
      draftModeEnabled &&
      process.env.NEXT_PUBLIC_VERCEL_ENV !== 'preview' &&
      window === parent
    ) {
      location.href = `/api/disable-draft?redirect=${location.pathname}`;
    }
  }, []);

  useStoryblok({
    draftModeEnabled,
  });

  return (
    <>
      <Analytics />
      <AnimateOnScroll />

      {!draftModeEnabled ? <CookieConsent locale={locale} /> : null}
    </>
  );
};
